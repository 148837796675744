"use client"

import clsx from "clsx"
import Link from "next/link"

import { Logo } from "@/components/icons"
import { fontMontserrat } from "@/config/fonts"
import { siteConfig } from "@/config/site"

export type ErrorType = "notFound" | "forbidden" | "systemError" | "other"

export type ErrorCardProps = {
  type: ErrorType
  customMessage?: string
  customDescription?: string
}

export const ErrorCard = (props: ErrorCardProps) => {
  const { type, customMessage, customDescription } = props

  const error = siteConfig.errors[type]

  const message = customMessage ?? error.message
  const description = customDescription ?? error.description

  return (
    <section className="flex flex-col items-center justify-center gap-5 px-6 py-20 text-white md:mx-auto">
      <h1
        className={clsx(
          "mt-10 flex gap-4 pb-10 pt-20 align-top font-mono text-[124px] font-bold leading-[124px]",
          fontMontserrat.variable,
        )}
      >
        {type === "notFound" || type === "forbidden"
          ? error.title.split("").map((char, i) =>
              char === "0" ? (
                // eslint-disable-next-line react/no-array-index-key
                <Logo key={`char-${char}-index_${i}`} size={124} />
              ) : (
                // eslint-disable-next-line react/no-array-index-key
                <span key={`char-${char}-index_${i}`}>{char}</span>
              ),
            )
          : error.title}
      </h1>
      {message !== undefined && <p className="text-center">{message}</p>}
      {description !== undefined && (
        <p className="text-center text-sm text-gray-100 ">{description}</p>
      )}

      {/* トップページに戻る */}
      <div className="flex flex-col items-center gap-4">
        <Link href="/">
          <button
            type="button"
            className="hover:bg-primary-dark flex items-center justify-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          >
            トップページに戻る
          </button>
        </Link>
      </div>
    </section>
  )
}
