"use client"

import { Avatar } from "@nextui-org/avatar"
import { Button } from "@nextui-org/button"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
} from "@nextui-org/dropdown"

import { TUserResponse } from "@/api/user"
import { ThemeSwitch } from "@/components/theme-switch"
import { PATH } from "@/constants/path"

type Props = {
  isLoggedIn: boolean
  user?: TUserResponse
}

export const UserStatus = ({ isLoggedIn, user }: Props) => {
  if (!isLoggedIn) {
    return (
      <div className="flex gap-2">
        <a href={PATH.Auth.LOGIN}>
          <Button variant="light">ログイン</Button>
        </a>
        <a href={PATH.Auth.SIGNUP}>
          <Button color="secondary">登録する</Button>
        </a>
      </div>
    )
  }

  if (!user) {
    return null
  }

  return (
    <Dropdown placement="bottom-end">
      <DropdownTrigger>
        <Avatar
          alt="profile image"
          className="h-8 w-8 cursor-pointer sm:h-12 sm:w-12"
          src={user.profileImgUrl}
          name={user.playerId}
          isBordered
          isFocusable
          radius="full"
          showFallback
        />
      </DropdownTrigger>
      <DropdownMenu aria-label="Profile Actions" variant="flat">
        <DropdownItem
          key="signin"
          className="h-14 cursor-default"
          isReadOnly
          textValue={user.playerId}
        >
          <div className="flex items-center gap-2">
            <Avatar
              alt="profile image"
              size="sm"
              src={user.profileImgUrl}
              name={user.playerId}
              isFocusable
              radius="full"
              showFallback
            />
            <p className="font-semibold">{user.playerId}</p>
          </div>
        </DropdownItem>
        <DropdownItem
          key="profile"
          startContent={<i className="ri-user-line text-lg" />}
          href={`/user/${user.playerId}`}
        >
          プロフィール
        </DropdownItem>
        <DropdownSection title="設定" showDivider>
          <DropdownItem
            key="info"
            startContent={<i className="ri-user-settings-line text-lg" />}
            href="/settings/info"
          >
            プレイヤー情報
          </DropdownItem>
          <DropdownItem
            key="notification"
            startContent={<i className="ri-notification-2-line text-lg" />}
            href="/settings/notification"
          >
            通知
          </DropdownItem>
          <DropdownItem
            key="account"
            startContent={<i className="ri-settings-3-line text-lg" />}
            href="/settings/account"
          >
            アカウント設定
          </DropdownItem>
        </DropdownSection>
        <DropdownSection>
          <DropdownItem key="theme-switch">
            <ThemeSwitch isShowText />
          </DropdownItem>
          <DropdownItem
            key="logout"
            startContent={<i className="ri-logout-box-r-line text-lg" />}
            href={PATH.Auth.LOGOUT}
          >
            ログアウト
          </DropdownItem>
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  )
}
